import { OpportunityDetails } from 'OpportunityBuilder/Forms/OpportunityDetails'
import { OpportunityDescription } from 'OpportunityBuilder/Forms/OpportunityDescription'
import { ReviewAndSubmit } from 'OpportunityBuilder/Forms/ReviewAndSubmit'
import { OpportunityDetailsForm } from 'OpportunityBuilder/FormContent/OpportunityDetailsForm'
import { OpportunityDescriptionForm } from 'OpportunityBuilder/FormContent/OpportunityDescriptionForm'
import i18n from 'i18next'


const fieldsByHeaderId = {
  opportunityDetails: [
    'name',
    'status',
    'job_url',
    'image',
    'location', 'location_id',
    'api_job_id',
    'workplace_model',
    'course_id',
    'employment_type',
    'salary_min', 'salary_max', 'salary_frequency',
    'close_date'
  ],
  opportunityDescription: [
    'description',
    'skills'
  ],
  reviewSubmit: [''],
}

const fileNameSuffix = '-fileFilename'

export const getOpportunityStepsContent = () => {
  const t = i18n.getFixedT(null, 'common')
  const steps = [
    {
      header: t('Opportunity Information'),
      links: [
        {
          id: 'opportunityDetails',
          label: t('Opportunity Details'),
          Component: OpportunityDetails,
          ReviewComponent: OpportunityDetailsForm,
          fields: fieldsByHeaderId['opportunityDetails']
        },
        {
          id: 'opportunityDescription',
          label: t('Opportunity Description'),
          Component: OpportunityDescription,
          ReviewComponent: OpportunityDescriptionForm,
          fields: fieldsByHeaderId['opportunityDescription']
        }
      ]
    },
    {
      header: t('Review'),
      links: [{
        id: 'reviewSubmit',
        label: t('Review'),
        Component: ReviewAndSubmit
      }]
    },
  ]

  let headersMap = {}
  let opportunityStepsContent = []

  steps.forEach((step, index) => {
    step.links.forEach(link => {
      opportunityStepsContent.push({ ...link, defaultIndex: index })
    })
    headersMap[index] = step.header
  })

  return { opportunityStepsContent, headersMap }
}

export const getFormIdFromFieldKey = (key) => {
  if (key.endsWith(fileNameSuffix)) {
    key = key.replace(fileNameSuffix, '')
  }
  for (const [headerId, fields] of Object.entries(fieldsByHeaderId)) {
    if (fields.includes(key)) {
      return headerId
    }
  }
}
