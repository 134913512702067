import
{
  Box, Button, Divider, Drawer, DrawerBody, DrawerContent,
  DrawerOverlay,
  Input, InputGroup, InputLeftElement, VStack
} from '@chakra-ui/react'
import { Switches } from 'components/partner/talent/listPage/searchDrawer/Switches'
import { H2 } from 'components/typography'
import { useTalentSearch } from 'hooks/partnerUx/useTalentSearch'
import { useTranslation } from 'i18n/TranslationContext'
import { IconContext } from 'react-icons'
import { FiSearch } from 'react-icons/fi'
import { H3 } from 'shared/typography'

const KeywordInput = ({ formValues, onChange }) => {
  return (
    <InputGroup fontFamily="Poppins" size="md" bgColor="white">
      <InputLeftElement pointerEvents="none">
        <IconContext.Provider value={{ color: 'gray.300' }}>
          {/* left icon */}
          <FiSearch />
        </IconContext.Provider>
      </InputLeftElement>
      {/* input field */}
      <Input
        placeholder="Search by keywords"
        name="keywords"
        value={formValues.keywords}
        onChange={onChange}
      />
    </InputGroup>
  )
}

const SearchDrawer = ({ isOpen, onClose, switchConfigs }) => {
  const { t } = useTranslation()
  const {
    formValues, lastSearchFilterCount, handleInputChange,
    searchFormValues, resetFormSearch
  } = useTalentSearch()

  const handleSearchClick = async () => {
    searchFormValues()
    onClose()
  }

  const handleRemoveFiltersClick = () => {
    resetFormSearch()
    onClose()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick()
    }
  }

  const bodyStyle = {
    background: `url(${process.env.PUBLIC_URL}/contact-request-bg.svg) 0 0 / 102% auto no-repeat local, 
                 linear-gradient( rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%), 
                 url(${process.env.PUBLIC_URL}/noise.png)`
  }

  const keywordDescription = t('Search for talent users by their Name, Email, Profession, and content written in their Bio.')
  const bodyDescription = t('Narrow your results when searching for qualified talent. Results shown will match all filters added.')

  return (
    <Drawer isOpen={isOpen} placement="right"
      onClose={onClose} size="sm"
      className="talent-search-drawer" boxShadow="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody onKeyDown={handleKeyDown} style={bodyStyle} pt={6}>
          <VStack spacing={4} align="stretch">
            <H2 mt={14}>{t('Search/Filter Enrolled Talent')}</H2>

            <Box fontFamily="Poppins" fontSize="16px">
              {bodyDescription}
            </Box>

            <Divider />

            {/* keyword input */}
            <KeywordInput formValues={formValues} onChange={handleInputChange} />

            <Box fontFamily="Poppins" fontSize="16px" color="gray.500">
              {keywordDescription}
            </Box>

            <Divider />

            <H3>{t('Filters')}</H3>

            {/* boolean filters */}
            <Switches formValues={formValues} onChange={handleInputChange} switchConfigs={switchConfigs} />

            {/* search button */}
            <Button
              className="apply-search-button"
              colorScheme="zettaBlue"
              size="lg"
              w="100%"
              onClick={handleSearchClick}
              mt={4}
            >
              {t('Search')}
            </Button>

            { lastSearchFilterCount && (
              <Button
                className="remove-filters-button"
                onClick={handleRemoveFiltersClick}
                fontSize="16px"
                fontWeight="500"
                padding="0 12px"
                mt="5px"
                colorScheme="zettaWhiteOutline"
              >
                {t('Remove All Filters')}
              </Button>
            )}

          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default SearchDrawer
