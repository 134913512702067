import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useUpdateOpportunityApplicationMutation } from 'features/opportunitiesApi'
import { OPPORTUNITY_APPLICATION_STATUS } from 'constants'
import { useMixpanelService } from 'services/mixpanelService'

const IveAppliedButton = ({ opportunityApplication, opportunity }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const { t } = useTranslation()
  const { trackEvent } = useMixpanelService()
  const [updateOpportunityApplication] = useUpdateOpportunityApplicationMutation()
  const hasApplied = opportunityApplication.status === OPPORTUNITY_APPLICATION_STATUS.APPLIED

  return (
    <Button
      colorScheme="zettaWhiteOutline"
      isLoading={isUpdating}
      isDisabled={hasApplied}
      display="block"
      width={{ base: '100%', md: 'fit-content' }}
      onClick={async () => {
        setIsUpdating(true)

        // Update the opp app status to applied
        try {
          await updateOpportunityApplication({
            id: opportunityApplication.id,
            status: OPPORTUNITY_APPLICATION_STATUS.APPLIED
          }).unwrap()

          // Track event for Mixpanel
          trackEvent(
            'Application Submitted',
            {
              opportunity_id: opportunity.id,
              opportunity_name: opportunity.name
            }
          )
        } catch (error) {
          console.error('Failed to update opportunity application:', error)
        } finally {
          setIsUpdating(false)
        }
      }}
    >
      {hasApplied ? t('Applied') : t('I\'ve Applied')}
    </Button>
  )
}

export default IveAppliedButton