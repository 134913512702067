import mixpanel from 'mixpanel-browser'
import { useRef } from 'react'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

export const useMixpanelService = () => {
  const previousPath = useRef()
  const {
    data: {
      id: userId,
    } = {},
    isFetching: isUserFetching,
  } = useGetCurrentUserQuery()

  const identifyUser = (userId) => {
    try {
      mixpanel.identify(userId)
    } catch (error) {
      console.error('Failed to identify user:', error)
    }
  }

  const trackEvent = (eventName, properties = {}) => {
    try {
      mixpanel.track(eventName, properties)
    } catch (error) {
      console.error('Failed to track event:', error)
    }
  }

  const trackPageView = (path, search) => {
    if (!isUserFetching) {
      userId && identifyUser(userId) // Set the distinct ID for the user
      const currentPath = path + search
      if (previousPath.current !== currentPath) {
        trackEvent('Page View', { url: currentPath })
        previousPath.current = currentPath
      }
    }
  }

  return {
    trackPageView,
    trackEvent
  }
}
