import { Flex } from '@chakra-ui/react'
import OpportunityItem from './OpportunityItem'
import { SaveOpportunityButton, ApplyButton, DismissButton } from 'components'

const TalentOpportunity = ({ type, talentUserId, ...props }) => {
  const getButtonsComponent = () => {
    switch (type) {
    case 'available':
      return (
        <Flex flexDirection="column" gap={2}>
          <ApplyButton opportunity={props} talentUserId={talentUserId} />
          <SaveOpportunityButton opportunityId={props.id} talentUserId={talentUserId} />
        </Flex>
      )
    case 'active':
      return (
        <Flex direction="column" gap={2}>
          <ApplyButton opportunity={props} talentUserId={talentUserId} />
          <DismissButton opportunity={props} />
        </Flex>
      )
    default:
      return null
    }
  }

  return (
    <OpportunityItem
      column2Value={props['company_name']}
      buttonsComponent={getButtonsComponent()}
      {...props}
    />
  )
}

export default TalentOpportunity
