import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Button, InputGroup } from '@chakra-ui/react'
import useKeyListener from 'hooks/useKeyListener'
import { AutoResizeTextarea } from 'components/talent/modalChatUx/AutoResizeTextarea'
import BeaconService from 'services/BeaconService'
import { useTranslation } from 'i18n/TranslationContext'

export const ChatFooter = () => {
  const { isLoading, error } = useSelector((state) => state.beaconChat)
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const dispatch = useDispatch()
  const beaconService = useRef(new BeaconService('compass', dispatch))

  const handleSendClick = () => {
    if ( !inputValue.length ) { return }

    beaconService.current.sendMessage(inputValue)
    setInputValue('')
  }

  // Listens for CMD/CTRL + ENTER to send the message
  useKeyListener({ keys: [13], includeModifierKey: true }, handleSendClick)

  return (
    <Flex direction="row" alignItems="center" width="100%" gap="12px"
      className="modal-footer-elements" py="12px" px="48px"
    >
      {/* <IconButton
        aria-label="Attach file"
        icon={<FaPaperclip size="1.5em" />}
        size="lg"
        variant="ghost"
        isDisabled
        _hover={{ bg: 'transparent', color: 'blue.500' }}
      /> */}
      <InputGroup>
        <AutoResizeTextarea
          fontFamily="Poppins"
          size="md"
          value={inputValue}
          disabled={isLoading}
          onChange={(e) => setInputValue(e.target.value)}
          resize="none"
          borderRadius="xl"
          bg="gray.100"
          _focus={{ bg: 'white', borderColor: 'blue.500' }}
          placeholder={t('Type your message...')}
        />
      </InputGroup>
      <Button
        isLoading={isLoading}
        colorScheme="zettaBlue"
        fontWeight={400}
        onClick={handleSendClick}
        size="lg"
        isDisabled={!inputValue || error}
      >
        {t('Send')}
      </Button>
    </Flex>
  )
}
