import React from 'react'
import { Box, Center } from '@chakra-ui/react'
import OrangeTape from './OrangeTape'

const OrangeTapeDisplay = ({ title, ...props }) => (
  <Box
    pr={[5, 10, 20]}
    mt={[-5, -7]}
    fontFamily="Spline Sans Mono"
    fontSize={['32px', '36px', '42px']}
    fontWeight="bold"
    lineHeight={['30px', '40px', '60px']}
    textTransform="uppercase"
    display="inline-flex"
    position="relative"
    color="white"
    zIndex={10}
  >
    <OrangeTape />
    <Center
      as="h1"
      pl={['12px', '22px', '40px']}
      pt={['10px', '16px']}
      pb={['12px', '18px']}
      {...props}
    >
      {title}
    </Center>
  </Box>
)

export default OrangeTapeDisplay
