import React from 'react'
import { Button, Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { formatLinkProtocols } from 'utils/string'

const ExternalLinkButton = ({ href, onClick, children, ...restProps }) => {
  const handleClick = async () => {
    if (onClick) {
      await onClick()
    }
    window.open(formatLinkProtocols(href), '_blank')
  }

  return (
    <Button
      {...restProps}
      isExternal
      as={Link}
      target="_blank"
      href={formatLinkProtocols(href)}
      onClick={handleClick}
      rightIcon={<ExternalLinkIcon />}
    >
      {children}
    </Button>
  )
}

export default ExternalLinkButton