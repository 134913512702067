import { Route, Routes } from 'react-router-dom'
import { OpportunityList } from './OpportunityList'
import { OpportunityPage } from './OpportunityPage'
import { OpportunityApplicationList } from './OpportunityApplicationList'

export const OpportunityFeature = () => {
  return (
    <Routes>
      <Route
        index
        element={<OpportunityList />}
      />
      <Route
        path=":opportunityId"
        element={<OpportunityPage />}
      />
      <Route
        path=":opportunityId/applications/*"
        element={<OpportunityApplicationList />}
      />
    </Routes>
  )
}
