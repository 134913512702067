import { HiBuildingOffice, HiHome } from 'react-icons/hi2'
import { HybridIcon } from 'components/icons'
import { HiOutlineShieldCheck } from 'react-icons/hi2'
import { HiOutlineEye } from 'react-icons/hi'
import {
  FaTooth,
  FaHandHoldingUsd,
  FaUmbrellaBeach,
  FaGraduationCap,
  FaUniversalAccess,
  FaRegKeyboard,
  FaChartLine,
  FaFileInvoiceDollar,
  FaFunnelDollar
} from 'react-icons/fa'

export const USER_ROLES = {
  TALENT: 'talent',
  PARTNER: 'partner',
  ADMIN: 'admin'
}

export const DIRECTION = {
  NEXT: 'next',
  PREVIOUS: 'previous'
}

export const URL = {
  FEEDBACK_FORM: 'https://k1l8hbybse1.typeform.com/to/HeG96yRs'
}

// The current state of a new user's signup process
export const SIGNUP_STATUS = {
  PROFILE_INCOMPLETE: 'profile_incomplete',
  PROFILE_COMPLETE: 'profile_complete',
  SELF_ID_SEEN: 'self_id_seen',
  SKILLS_BIO_SEEN: 'skills_bio_seen',
  EMAIL_CONFIRMED: 'email_confirmed'
}

// If the Beacon model is showing the splash view or the chat view
export const BEACON_MODE = {
  SPLASH: 'splash',
  CHAT: 'chat'
}

export const TALENT_QUIZ_MODAL_MODE = {
  SPLASH: 'splash',
  QUIZ: 'quiz'
}

export const TALENT_QUIZ_MODE = {
  REVIEW: 'review',
  ACTIVE: 'active'
}

export const TALENT_QUIZ_BUBBLE_TYPE = {
  USER: 'user',
  SYSTEM: 'system'
}

export const TALENT_QUIZ_SYSTEM_MSG_TYPE = {
  GREETING: 'greeting',
  ASK_QUESTION: 'ask_queston',
  CORRECT_ANSWER: 'correct_answer',
  INCORRECT_ANSWER: 'incorrect_answer',
  QUIZ_COMPLETE: 'quiz_complete',
}

// Determines where we start in the Compass flow, depending on the
// Disability option selected in Self-ID form.
export const COMPASS_MODE = {
  BARRIERS_ANALYSIS: 'barriers_analysis',
  SKILLS_STEPS_ROLES: 'skills_steps_roles'
}

export const COMPASS_STEPS = {
  GET_BARRIERS: 'get_barriers',
  HANDLE_SSR_RESPONSE: 'handle_ssr_response',
  GET_ROLE: 'get_role',
  GET_EXPERIENCES: 'get_experiences',
  INITIAL_SKILL_LIST: 'initial_skill_list',
  SKILL_LOOP_FINAL_DELIVERABLE: 'skill_loop_final_deliverable',
  HANDLE_PUSH_RESPONSE: 'handle_push_response'
}

export const FEATURE_FLAGS = {
  COMPASS: 'compass',
  SLIDE_DECK_UPLOAD: 'slide_deck_upload',
}

export const REGEX_PATTERNS = {
  URL: new RegExp(
    '^(https?:\\/\\/)?' + // protocol (optional)
    '((([a-zA-Z0-9\\u00a1-\\uffff][a-zA-Z0-9\\u00a1-\\uffff-]{0,62})?[a-zA-Z0-9\\u00a1-\\uffff]\\.)+' + // domain name with IDN support
    '[a-zA-Z\\u00a1-\\uffff]{2,}\\.?)' + // TLD
    '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-zA-Z0-9\\u00a1-\\uffff%_.~+]*)*' + // port and path
    '(\\?[;&a-zA-Z0-9\\u00a1-\\uffff%_.~+=-]*)?' + // query string
    '(\\#[-a-zA-Z0-9\\u00a1-\\uffff_]*)?$', // fragment locator
    'i' // case insensitive
  )
}

export const COURSE_BUILDER = {
  EPISODE_LIMIT: 50,
  EPISODE_LIMIT_HUMAN_READABLE: 'fifty'
}

export const COURSE_STEP_MEDIUM = {
  VIDEO: 'video',
  SLIDE_DECK: 'deck'
}

export const SERVER_FILE_UPLOAD_FILEDS = [
  'course_expert_image_0',
  'course_expert_image_1',
  'course_expert_image_2',
  'image'
]

export const NO_REDIRECT = {
  PATHS: [
    '/',
    '/sign_in',
    '/sign_up',
    '/talent/onboard',
    '/talent/onboard/self-id',
    '/email_confirmation'
  ]
}

export const MEDIA_UPLOAD_PATHS = (episodeNum, courseSlug) => (
  {
    [`episode_${episodeNum}_video`]: {
      primary: `courses/${courseSlug}/episodes/${Number(episodeNum) + 1}/episode1.mp4`,
    },
    [`episode_${episodeNum}_deck`]: {
      primary: `courses/${courseSlug}/episodes/${Number(episodeNum) + 1}/slidedeck`,
    },
    [`episode_${episodeNum}_thumbnail`]: {
      primary: `courses/${courseSlug}/episodes/${Number(episodeNum) + 1}/poster@2x.jpeg`,
      secondary: `courses/${courseSlug}/public_profile/steps/${Number(episodeNum) + 1}/poster@2x.jpeg`,
    },
    [`episode_${episodeNum}_transcript`]: {
      primary: `courses/${courseSlug}/episodes/${Number(episodeNum) + 1}/captions.vtt`,
    },
    [`episode_${episodeNum}_audio_description`]: {
      primary: `courses/${courseSlug}/episodes/${Number(episodeNum) + 1}/descriptions.vtt`,
    },
    [`episode_${episodeNum}_sign_language_video`]: {
      primary: `courses/${courseSlug}/episodes/${Number(episodeNum) + 1}/asl.mp4`,
    },
    'intro_video': {
      primary: `courses/${courseSlug}/episodes/1/episode1.mp4`,
      secondary: `courses/${courseSlug}/public_profile/bg-video.mp4`
    },
    'intro_thumbnail': {
      primary: `courses/${courseSlug}/episodes/1/poster@2x.jpeg`,
      secondary: `courses/${courseSlug}/public_profile/steps/1/poster@2x.jpeg`
    },
    'intro_transcript': {
      primary: `courses/${courseSlug}/episodes/1/captions.vtt`,
    },
    'intro_audio_description': {
      primary: `courses/${courseSlug}/episodes/1/descriptions.vtt`,
    },
    'intro_sign_language_video': {
      primary: `courses/${courseSlug}/episodes/1/asl.mp4`,
    },
  }
)

export const MEDIA_DELETE_PATHS = (episodeNum, courseSlug) => {
  // Modify paths here as necessary for deletion
  const delete_paths = MEDIA_UPLOAD_PATHS(episodeNum, courseSlug)

  // --- Slide deck extracted components should be deleted when the slide deck is deleted
  delete_paths[`episode_${episodeNum}_deck`]['secondary'] = `courses/${courseSlug}/episodes/${Number(episodeNum) + 1}/deck_files`

  return delete_paths
}

export const IGNORE_NAV_BLOCKER = { state: 'ignoreBlocker' }

export const LOCALE_OPTIONS = {
  ENGLISH: {
    label: 'English',
    key: 'en'
  },
  FRENCH: {
    label: 'French',
    key: 'fr'
  },
  GERMAN: {
    label: 'German',
    key: 'de'
  },
  PORTUGUESE_BRAZIL: {
    label: 'Portuguese (Brazil)',
    key: 'pt-br'
  },
  SPANISH_LATIN: {
    label: 'Spanish (Latin)',
    key: 'es-419'
  },
  SPANISH_SPAIN: {
    label: 'Spanish (Spain)',
    key: 'es-es'
  },
  ITALIAN: {
    label: 'Italian',
    key: 'it'
  }
}

export const DEFAULT_LOCALE = 'en'

export const UPLOAD_MEDIA_TYPE = (t) => ({
  EPISODE_VIDEO: {
    fileType: {
      accept: 'video/mp4',
      errorMessage: t('Invalid file type')
    },
    // The maximum length of a video is 10 minutes. Place into seconds
    lengthLimit: {
      size: 10 * 60,
      errorMessage: t('Video length cannot exceed 10 minutes.')
    },
    // The maximum size of an video is 250MB
    sizeLimit: {
      size: 250 * 1024 * 1024,
      errorMessage: t('Video File size cannot exceed 250 MB.')
    }
  },
  SLIDE_DECK: {
    fileType: {
      accept: 'application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
      errorMessage: t('Invalid file type')
    },
    // The maximum size of a slide deck is 25MB
    sizeLimit: {
      size: 25 * 1024 * 1024,
      errorMessage: t('Slide Deck File size cannot exceed 25 MB.')
    }
  },
  THUMBNAIL: {
    fileType: {
      accept: 'image/png, image/jpeg',
      errorMessage: t('Invalid file type')
    },
    // The maximum size of an image is 5MB
    sizeLimit: {
      size: 5 * 1024 * 1024,
      errorMessage: t('Thumbnail File size cannot exceed 5 MB.')
    }
  },
  VTT: {
    fileType: {
      accept: 'text/vtt',
      errorMessage: t('Invalid file type')
    },
  },
  ASSIGNMENT: {
    PDF: {
      fileType: {
        accept: 'application/pdf',
        errorMessage: t('Invalid file type')
      },
      emptyStateLabel: t('Upload Assignment in PDF file format (up to 10 MB).'),
      // The maximum size of a PDF is 10MB
      sizeLimit: {
        size: 10 * 1024 * 1024,
        errorMessage: t('The file is too big.')
      }
    },
    VIDEO: {
      fileType: {
        accept: 'video/mp4',
        errorMessage: t('Invalid file type')
      },
      emptyStateLabel: t('Upload Assignment in MP4 file format (up to 150 MB).'),
      // The maximum size of a video is 150MB
      sizeLimit: {
        size: 150 * 1024 * 1024,
        errorMessage: t('The file is too big.')
      }
    }
  }
})

export const EVENTS = {
  BEACON: {
    INIT_BOT_COMPLETED: 'initBotCompleted',
  }
}


export const OPPORTUNITY_APPLICATION_STATUS = {
  CREATED: 'created',
  STARTED: 'started',
  APPLIED: 'applied',
  INTERVIEWING: 'interviewing',
  OFFER_SENT: 'offer_sent',
  REJECTED: 'rejected',
  HIRED: 'hired'
}

export const OPPORTUNITY_SALARY_FREQUENCY_LABELS = (t) => ({
  hourly: t('hour'),
  weekly: t('week'),
  monthly: t('month'),
  annually: t('year'),
  project_basis: t('project')
})

export const LOCATION_OPTIONS = {
  IN_PERSON: 'in_person',
  REMOTE: 'remote',
  HYBRID: 'hybrid'
}

export const OPPORTUNITY_WORKPLACE_MODEL_DISPLAY = (t) => ({
  [LOCATION_OPTIONS.REMOTE]: { label: t('Remote'), icon: HiHome },
  [LOCATION_OPTIONS.IN_PERSON]: { label: t('In-Person'), icon: HiBuildingOffice },
  [LOCATION_OPTIONS.HYBRID]: { label: t('Hybrid'), icon: HybridIcon }
})

export const OPPORTUNITY_BENEFIT_TAGS = {
  'Medical': HiOutlineShieldCheck,
  'Vision': HiOutlineEye,
  'Dental': FaTooth,
  '401(k)': FaHandHoldingUsd,
  'Generous/Unlimited PTO': FaUmbrellaBeach,
  'Fully Remote': FaRegKeyboard,
  'Stock Options/Equity': FaChartLine,
  'Career Development/Education': FaGraduationCap,
  'Home Office Stipend': FaFileInvoiceDollar,
  'Wellness Stipend': FaFunnelDollar,
  'On-Site Amenities': FaUniversalAccess
}
