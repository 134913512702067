import React, { useState } from 'react'
import { Button, Spinner, Link as ChakraLink } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import {
  useUpdateOpportunityApplicationMutation
} from 'features/opportunitiesApi'
import { buttonStyles } from './buttonStyles'
import { Link } from 'react-router-dom'
import { OPPORTUNITY_APPLICATION_STATUS } from 'constants'
import ExternalApplyButton from './ExternalApplyButton'

const ApplyButton = ({ opportunity, talentUserId, ...props }) => {
  const [updateOpportunityApplication] = useUpdateOpportunityApplicationMutation()

  const { t } = useTranslation()
  const { primaryButtonStyle } = buttonStyles()
  const opportunityApplication = opportunity.opportunity_applications[0]
  const [isApplying, setIsApplying] = useState(false)

  const handleApply = async (status) => {
    if (status === 'applied') {
      setIsApplying(true)
      try {
        await updateOpportunityApplication({
          id: opportunityApplication.id,
          status
        }).unwrap()
      } catch (error) {
        console.error('Failed to apply for opportunity:', error)
      } finally {
        setIsApplying(false)
      }
    } else {
      try {
        await updateOpportunityApplication({
          id: opportunityApplication.id,
          status
        }).unwrap()
      } catch (error) {
        console.error('Failed to update opportunity application:', error)
      }
    }
  }

  const getButtonProps = () => {
    if (opportunityApplication?.status === OPPORTUNITY_APPLICATION_STATUS.APPLIED) {
      return {
        buttonText: t('Under Review'),
        buttonStatus: '',
        buttonLink: '',
        isDisabled: true,
        showDismissButton: false
      }
    }
    if (opportunityApplication?.course_finished && !opportunity.job_url) {
      return {
        buttonText: t('Apply Now'),
        buttonStatus: OPPORTUNITY_APPLICATION_STATUS.APPLIED,
        buttonLink: '',
        isDisabled: false,
        showDismissButton: true
      }
    }
    if (opportunityApplication?.status === OPPORTUNITY_APPLICATION_STATUS.CREATED &&
      !opportunityApplication?.course_started &&
      opportunity?.course_id) {
      return {
        buttonText: t('Begin'),
        buttonStatus: OPPORTUNITY_APPLICATION_STATUS.CREATED,
        buttonLink: `/courses/${opportunity.course_id}`,
        isDisabled: false,
        showDismissButton: true
      }
    }
    if (opportunityApplication?.course_started && !opportunityApplication?.course_finished) {
      return {
        buttonText: t('Continue'),
        buttonStatus: OPPORTUNITY_APPLICATION_STATUS.STARTED,
        buttonLink: `/courses/${opportunity.course_id}`,
        isDisabled: false,
        showDismissButton: true
      }
    }
    return {
      buttonText: '',
      buttonStatus: '',
      buttonLink: '',
      isDisabled: false,
      showDismissButton: true
    }
  }

  const { buttonText, buttonStatus, buttonLink, isDisabled } = getButtonProps()

  const ButtonComponent = (
    <>
      <Button
        onClick={() => handleApply(buttonStatus)}
        {...primaryButtonStyle}
        isDisabled={isDisabled || isApplying}
        {...props}
      >
        {isApplying ? <Spinner size="sm" /> : buttonText}
      </Button>
    </>
  )

  return (
    <>
      <ExternalApplyButton
        opportunity={opportunity}
        talentUserId={talentUserId}
        opportunityApplication={opportunityApplication}
      />
      {buttonLink ? (
        <ChakraLink as={Link} to={buttonLink} width={['100%', '100%', 'auto']}>
          {ButtonComponent}
        </ChakraLink>
      ) : (
        buttonText && ButtonComponent
      )}
    </>
  )
}

export default ApplyButton
