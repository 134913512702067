import React from 'react'
import {
  Box,
  Image,
  Heading,
  Link as ChakraLink,
  Icon,
  Wrap,
  WrapItem,
  Tag
} from '@chakra-ui/react'
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2'
import { OrangeTapeDisplay } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { parseWithoutStyles } from 'utils/string'
import parse from 'html-react-parser'
import { formatLinkProtocols } from 'utils/string'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import InnerContainer from 'shared/InnerContainer'
import { OPPORTUNITY_BENEFIT_TAGS } from 'constants'

const PartnerLogo = ({ photoUrl, companyName }) => {
  return (
    <Box mt={12} mb={5}>
      {photoUrl ? (
        <Image
          src={photoUrl}
          alt={companyName || 'Company Logo'}
          boxSize="100px"
          borderRadius="lg"
          bg="white"
        />
      ) : (
        <Box
          boxSize="100px"
          borderRadius="lg"
          bg="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={HiOutlineBuildingOffice2} boxSize="60px" color="gray.700" />
        </Box>
      )}
    </Box>
  )
}

const PartnerCompanyName = ({ companyName, companyWebsite }) => {
  const { t } = useTranslation()

  const heading = (
    <Heading as="h2" fontFamily="Poppins" fontSize="40px" color="white" textDecoration="underline" display="inline">
      {companyName || t('Company Name Not Available')}
    </Heading>
  )

  if (companyWebsite) {
    return (
      <ChakraLink href={formatLinkProtocols(companyWebsite)} isExternal display="flex" alignItems="center">
        {heading}
        <Icon as={ExternalLinkIcon} boxSize={9} ml="2" aria-hidden="true" display="inline" />
      </ChakraLink>
    )
  }

  return heading
}

const PartnerSection = ({ partnerProfile }) => {
  const { t } = useTranslation()
  if (!partnerProfile) return null

  const benefitsList = partnerProfile.company_benefits
    ? partnerProfile.company_benefits.split(',').map(benefit => benefit.trim())
    : []
  const companyDescription = partnerProfile.company_description || ''
  const companyAccessibilityPolicy = partnerProfile.company_accessibility_policy || ''

  return (
    <Box bg="#0A1A40" mx={-8} px={8} py={12} pb={40} mb="-75px" color="white">
      <InnerContainer mt={12}>
        <OrangeTapeDisplay as="h2" title={t('About the Company')} fontSize="36px" pb="16px" pt="16px" />
        <PartnerLogo photoUrl={partnerProfile.photo_url} companyName={partnerProfile.company_name} />
        <PartnerCompanyName companyName={partnerProfile.company_name} companyWebsite={partnerProfile.company_website} />

        <Box
          mt={4}
          whiteSpace="pre-wrap"
          fontFamily="Poppins"
          className="about-block__description"
          listStylePosition="inside"
        >
          {parse(companyDescription, parseWithoutStyles)}
        </Box>

        {benefitsList.length > 0 && (
          <Box mt={12}>
            <Heading as="h3" fontSize="36px" fontFamily="Poppins" fontWeight="medium" color="white" mb={6}>
              {t('Benefits')}
            </Heading>
            <Wrap spacing={4}>
              {benefitsList.map(benefit => (
                <WrapItem key={benefit}>
                  <Tag
                    size="lg"
                    bg="#0a1a40"
                    color="white"
                    borderRadius="full"
                    px={3}
                    py={2}
                    fontWeight="light"
                    border="2px solid"
                    borderColor="white"
                    fontSize={['16px', '18px', '20px']}
                    fontFamily="Poppins"
                  >
                    <Icon as={OPPORTUNITY_BENEFIT_TAGS[benefit]} boxSize={5} mr="2" aria-hidden="true" display="inline" />
                    {benefit}
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        )}

        {companyAccessibilityPolicy && (
          <Box mt={12}>
            <Heading as="h3" fontSize="36px" fontFamily="Poppins" fontWeight="medium" color="white" mb={6}>
              {t('How we\'re accessible')}
            </Heading>
            <Box
              maxW="80%"
              whiteSpace="pre-wrap"
              fontFamily="Poppins"
              className="about-block__description"
              listStylePosition="inside"
            >
              {parse(companyAccessibilityPolicy, parseWithoutStyles)}
            </Box>
          </Box>
        )}
      </InnerContainer>
    </Box>
  )
}

export default PartnerSection
