import React from 'react'
import { Box } from '@chakra-ui/react'
import { H2, Body } from 'components/typography'
import { useGetTextObjectQuery } from 'features/awsApiSlice'
import { useTranslation } from 'i18n/TranslationContext'

const SpeakerNotes = ({ slide }) => {
  const { t } = useTranslation()
  const { data: speakerNotes } = useGetTextObjectQuery({ objectURL: slide?.speakerNotes })
  const hasSpeakerNotes = speakerNotes && speakerNotes.length > 0

  return (
    <Box
      bgColor="white"
      id="speaker-notes"
      position="relative"
      flex="1"
      flexBasis="30%"
      minHeight="225px"
      aria-hidden={!hasSpeakerNotes}
      tabIndex="-1"
    >
      <Box
        overflow="hidden"
        display="flex"
        flexDirection="column"
        position="absolute"
        padding="24px"
        height="100%"
      >
        <H2
          fontSize="18px"
          color="#0F204B"
          mb="12px"
        >
          {t('Notes')}
        </H2>
        {hasSpeakerNotes ? (
          <Body overflowY="scroll" whiteSpace="pre-wrap" >
            {speakerNotes}
          </Body>
        ) : (
          <Body color="gray.600">
            <i>{t('No notes for this slide.')}</i>
          </Body>
        )}
      </Box>
    </Box>
  )
}

export default SpeakerNotes
