import { Box, Center, Flex, Spinner, useUpdateEffect } from '@chakra-ui/react'
import { NextStepButton } from 'components/talent/quiz/modalElements/NextStepButton'
import { QuestionAnswerOptions } from 'components/talent/quiz/modalElements/QuestionAnswerOptions'
import { SendButton } from 'components/talent/quiz/modalElements/SendButton'
import { SystemBubble } from 'components/talent/quiz/modalElements/bubbles'
import { TALENT_QUIZ_BUBBLE_TYPE, TALENT_QUIZ_MODE, TALENT_QUIZ_SYSTEM_MSG_TYPE, DIRECTION } from 'constants'
import { useUpdateEnrolmentStepStateQuizMutation } from 'features/coursesApi'
import { useFetchQuizByStepIdQuery } from 'features/quiz/quizzesApi'
import { addLiveBubble } from 'features/quiz/talentQuizSlice'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useQuizTakingText } from 'hooks/quizzes/talent/useQuizTakingText'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { hasCompletedQuiz, hasCorrectAnswer } from 'utils/quizzes/talentQuiz'
import { useCurrentCourseQuery } from 'CourseFeature/CoursePage/useCurrentCourseQuery'
import { useGetStepInfo } from 'CourseFeature/hooks'
import { useCreateOpportunityApplicationMutation } from 'features/opportunitiesApi'
import { useTranslation } from 'i18n/TranslationContext'

export const coreIconStyles = {
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  width: '30px', height: '30px', bgColor: 'white',
}

const NoMessagesElement = () => {
  return (
    <Center p={10} flexDirection="column">
      <Spinner size="xl" color="blue.500" />
      <Box mt={10} fontSize="xl" color="grey">{'{ Fetching Quiz... }'}</Box>
    </Center>
  )
}

export const QuizContainer = ({ stepId, onNextStepClick }) => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const nextStepInfo = useGetStepInfo(stepId, DIRECTION.NEXT)
  const isFinalStep = nextStepInfo?.isAssignment
  const isCourseComplete = isFinalStep || !nextStepInfo

  const { data: quiz = {}, isLoading: quizLoading, isError: quizError } = useFetchQuizByStepIdQuery(String(stepId))
  const [updateEnrolmentStepState] = useUpdateEnrolmentStepStateQuizMutation()

  const { liveBubbles, quizMode, modalIsOpen } = useSelector((state) => state.talentQuiz)
  const { data: currentUser = {}, loading: loadingUser } = useGetCurrentUserQuery()
  const { systemText } = useQuizTakingText()
  const bottomRef = useRef(null)
  const hasInitedBubbles = useRef(false)

  const talentUserId = currentUser?.talent_profile?.id
  const { data: courseData, isLoading: isCourseLoading } = useCurrentCourseQuery()
  const [createOpportunityApplication] = useCreateOpportunityApplicationMutation()

  const isReviewMode = quizMode === TALENT_QUIZ_MODE.REVIEW
  const nextQuestionIndex = quiz?.questions?.findIndex((question) => !hasCorrectAnswer(question))
  const nextQuestion = quiz?.questions?.[nextQuestionIndex]

  const systemAskQuestionObj = useMemo(() => ({
    bubbleType: TALENT_QUIZ_BUBBLE_TYPE.SYSTEM,
    messageType: TALENT_QUIZ_SYSTEM_MSG_TYPE.ASK_QUESTION,
  }), [])

  const systemGreetingObj = useMemo(() => ({
    bubbleType: TALENT_QUIZ_BUBBLE_TYPE.SYSTEM,
    messageType: TALENT_QUIZ_SYSTEM_MSG_TYPE.GREETING,
    messageHtml: systemText.greeting
  }), [systemText.greeting])

  const handleApply = async () => {
    if (courseData?.opportunity_id && talentUserId) {
      try {
        await createOpportunityApplication({
          talentUserId: talentUserId,
          opportunityId: courseData.opportunity_id,
          status: 'applied'
        }).unwrap()
        navigate('/courses')
      } catch (error) {
        console.error('Failed to apply for opportunity:', error)
      }
    }
  }

  const initReviewModeBubbles = useCallback(() => {
    dispatch(addLiveBubble(systemGreetingObj))

    quiz.questions.forEach((question) => {
      dispatch(addLiveBubble({ ...systemAskQuestionObj, questionId: question.id }))
    })

    dispatch(
      addLiveBubble({
        bubbleType: TALENT_QUIZ_BUBBLE_TYPE.SYSTEM,
        messageType: TALENT_QUIZ_SYSTEM_MSG_TYPE.QUIZ_COMPLETE,
        messageHtml: systemText.quiz_complete,
      })
    )
  }, [dispatch, quiz.questions, systemAskQuestionObj, systemGreetingObj, systemText.quiz_complete])

  const initActiveModeBubbles = useCallback(() => {
    dispatch(
      addLiveBubble(systemGreetingObj)
    )

    const answeredQuestions = quiz.questions.filter((question) =>
      hasCorrectAnswer(question)
    )

    if (answeredQuestions.length > 0) {
      answeredQuestions.forEach((question) => {
        dispatch(addLiveBubble({ ...systemAskQuestionObj, questionId: question.id }))
      })
      dispatch(addLiveBubble({ ...systemAskQuestionObj, questionId: quiz.questions[nextQuestionIndex].id }))
    } else {
      dispatch(addLiveBubble({ ...systemAskQuestionObj, questionId: quiz.questions[0].id }))
    }
  }, [dispatch, quiz.questions, systemAskQuestionObj, nextQuestionIndex, systemGreetingObj])

  useEffect(() => {
    if (!quiz.questions || !modalIsOpen) return

    if (hasCompletedQuiz(quiz)) {
      if (!hasInitedBubbles.current) {
        hasInitedBubbles.current = true
        initReviewModeBubbles()
      }
    } else {
      if (!hasInitedBubbles.current) {
        hasInitedBubbles.current = true
        initActiveModeBubbles()
      }
    }
  }, [quiz, modalIsOpen, hasInitedBubbles, dispatch, initReviewModeBubbles, initActiveModeBubbles])

  useEffect(() => {
    if (hasCompletedQuiz(quiz) && !isReviewMode) {
      updateEnrolmentStepState({ courseId, stepId })

      if (isCourseComplete && !nextStepInfo?.isAssignment && courseData?.opportunity_id) {
        dispatch(
          addLiveBubble({
            bubbleType: TALENT_QUIZ_BUBBLE_TYPE.SYSTEM,
            messageType: TALENT_QUIZ_SYSTEM_MSG_TYPE.COURSE_COMPLETE_WITH_OPPORTUNITY,
            messageHtml: t(`You've completed the requirements for ${courseData?.opportunity?.name}! Apply now to be considered for this exciting opportunity.`),
          })
        )
      }
    }
  }, [quiz, updateEnrolmentStepState, stepId, courseId, isReviewMode, courseData, dispatch, t, isCourseComplete, nextStepInfo?.isAssignment])

  useUpdateEffect(() => {
    if ( !isReviewMode ) bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [liveBubbles])

  if (quizLoading || loadingUser || isCourseLoading) {
    return <NoMessagesElement />
  }

  if (quizError) {
    return <div>Request Error: {quizError.message}</div>
  }

  if (!quiz.questions || !currentUser.id) {
    return 'ERROR: QUIZ OR USER NOT FOUND'
  }

  const getQuestionById = (questionId) => quiz.questions.find((question) => question.id === questionId)

  const getQuestionAfterId = (questionId) => {
    const questionIndex = quiz.questions.findIndex((question) => question.id === questionId)
    return quiz.questions[questionIndex + 1]
  }

  const renderGreetingBubble = (index) => (
    <SystemBubble key={index} message={{ html: systemText.greeting }} />
  )

  const renderQuestionBubble = (index, questionId) => (
    <QuestionAnswerOptions key={index} question={getQuestionById(questionId)} />
  )

  const renderCorrectAnswerBubble = (index, questionId, remainingQuestionCount) => {
    const nextQuestion = getQuestionAfterId(questionId)
    const html = systemText.correct_answer(remainingQuestionCount)

    return nextQuestion ? [
      <SystemBubble key={`${html}|${index}`} message={{ html }} isCorrectAnswer />,
      renderQuestionBubble(`question|${index}`, nextQuestion.id),
    ] : renderQuizCompleteBubble(index)
  }

  const renderIncorrectAnswerBubble = (index, questionId ) => [
    <SystemBubble key={`${systemText.incorrect_answer}|${index}`}
      message={{ html: systemText.incorrect_answer }} isIncorrectAnswer
    />,
    renderQuestionBubble(`question|${index}`, questionId),
  ]

  const renderQuizCompleteBubble = (index) => {
    if (isCourseComplete && !nextStepInfo?.isAssignment && courseData?.opportunity_id) {
      return (
        <SystemBubble
          key={`quiz-complete-with-opportunity|${index}`}
          message={{
            html: t(`You've completed the requirements for ${courseData?.opportunity?.name}! Apply now to be considered for this exciting opportunity.`)
          }}
        />
      )
    }
    return (
      <SystemBubble
        key={`quiz-complete|${index}`}
        message={{ html: systemText.quiz_complete }}
      />
    )
  }

  const systemBubbleRenderers = {
    [TALENT_QUIZ_SYSTEM_MSG_TYPE.GREETING]: renderGreetingBubble,
    [TALENT_QUIZ_SYSTEM_MSG_TYPE.ASK_QUESTION]: renderQuestionBubble,
    [TALENT_QUIZ_SYSTEM_MSG_TYPE.CORRECT_ANSWER]: renderCorrectAnswerBubble,
    [TALENT_QUIZ_SYSTEM_MSG_TYPE.INCORRECT_ANSWER]: renderIncorrectAnswerBubble,
    [TALENT_QUIZ_SYSTEM_MSG_TYPE.QUIZ_COMPLETE]: renderQuizCompleteBubble,
  }

  const chatBubbles = liveBubbles.flatMap((bubble, index) => {
    const { bubbleType, messageType, questionId, remainingQuestionCount } = bubble
    if (bubbleType === TALENT_QUIZ_BUBBLE_TYPE.SYSTEM) {
      const renderer = systemBubbleRenderers[messageType]
      return renderer ? renderer(index, questionId, remainingQuestionCount) : []
    }
    return []
  })

  const sendButton = (
    <Flex justifyContent="flex-end" pr="64px">
      {nextQuestion ? (
        <SendButton question={nextQuestion} />
      ) : (
        <NextStepButton
          stepId={stepId}
          courseId={courseId}
          onNextStepClick={onNextStepClick}
          talentUserId={talentUserId}
          opportunityId={courseData?.opportunity_id}
          isCourseComplete={isCourseComplete}
          onApply={handleApply}
        />
      )}
    </Flex>
  )

  return (
    <div className="quiz-container">
      {chatBubbles.flat()}
      { !isReviewMode && sendButton }
      <Box ref={bottomRef} />
    </div>
  )
}
