import { Box, Card, CardBody } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { MarkdownRenderer } from 'components/talent/modalChatUx/MarkdownRenderer'

const SystemTail = ({ isCorrectAnswer, isIncorrectAnswer }) => {
  let filename
  if (isCorrectAnswer) {
    filename = 'chat-bubble-arrow-correct.png'
  } else if (isIncorrectAnswer) {
    filename = 'chat-bubble-arrow-wrong.png'
  } else {
    filename = 'chat-bubble-arrow-grey.png'
  }

  return (
    <img src={`${process.env.PUBLIC_URL}/${filename}`} alt="" width="22px"
      style={{ position: 'relative', top: '36px', left: '-19px', zIndex: '1' }}
    />
  )
}

const UserTail = () => (
  <img src={`${process.env.PUBLIC_URL}/chat-bubble-arrow-blue.svg`} alt=""
    style={{ position: 'absolute', right: '-18px', top: '25px', zIndex: '1' }} />
)

const IncorrectIcon = () => (
  <img src={`${process.env.PUBLIC_URL}/FaTimesCircle.svg`} alt="" />
)

const CorrectIcon = () => (
  <img src={`${process.env.PUBLIC_URL}/FaCheckCircle.svg`} alt="" />
)

export const ChatBubble = ({ message, isUserMessage, isCorrectAnswer, isIncorrectAnswer }) => {
  const SYSTEM_BORDER_COLOR = '#959595'
  const DARK_BLUE = '#0F204B'
  const bkgColor = isUserMessage ? DARK_BLUE : 'rgba(188, 39, 82, 0.1)'
  const bubbleTail = isUserMessage ? <UserTail />
    : <SystemTail isCorrectAnswer={isCorrectAnswer} isIncorrectAnswer={isIncorrectAnswer} />
  const isSystemFeedback = !isUserMessage && (isCorrectAnswer || isIncorrectAnswer)

  // Define the styles hash
  const systemStyles = {
    question: {
      backgroundColor: '#EFEFF0',
      borderColor: SYSTEM_BORDER_COLOR,
      icon: null,
    },
    correct: {
      backgroundColor: '#DDE9E3',
      borderColor: '#007037',
      icon: <CorrectIcon />,
    },
    wrong: {
      backgroundColor: '#F2DDE5',
      borderColor: '#CD0050',
      icon: <IncorrectIcon />,
    },
  }

  // Determine the current state
  const currentState = isCorrectAnswer ? 'correct' : isIncorrectAnswer ? 'wrong' : 'question'

  // Determine the message content
  let messageContent
  if (message.markdown) {
    messageContent = <MarkdownRenderer message={message.markdown} />
  } else if (message.html) {
    messageContent = parse(message.html)
  } else {
    messageContent = message.component
  }

  return (
    <Box
      className={isUserMessage ? 'user-bubble' : 'system-bubble'}
      position="relative"
      ml={isUserMessage ? '0' : '10px'}
      mr={isUserMessage ? '8px' : 'auto'}
      top={isUserMessage ? '0' : '-10px'}
    >
      {bubbleTail}

      <Card bg={isUserMessage ? bkgColor : systemStyles[currentState].backgroundColor} maxWidth="fit-content"
        borderRadius="20px" p={1} border="1px" borderColor={isUserMessage ? DARK_BLUE : systemStyles[currentState].borderColor}
      >
        <CardBody maxW="700px" overflow="auto">
          {isSystemFeedback &&
          <Box className="system-response-icon" mb={4}>
            {systemStyles[currentState].icon}
          </Box>
          }

          {messageContent}
        </CardBody>
      </Card>
    </Box>
  )
}

ChatBubble.propTypes = {
  message: PropTypes.object.isRequired,
  photoUrl: PropTypes.string,
  icon: PropTypes.node,
  fullName: PropTypes.string,
  isUserMessage: PropTypes.bool.isRequired,
}




