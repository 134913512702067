import React from 'react'
import {
  useCreateOpportunityApplicationMutation
} from 'features/opportunitiesApi'
import { useTranslation } from 'i18n/TranslationContext'
import { buttonStyles } from './buttonStyles'
import { useMixpanelService } from 'services/mixpanelService'
import ExternalLinkButton from './ExternalLinkButton'
import { OPPORTUNITY_APPLICATION_STATUS } from 'constants'

const ExternalApplyButton = ({ opportunity, talentUserId, opportunityApplication }) => {
  const { t } = useTranslation()
  const { primaryButtonStyle } = buttonStyles()
  const { trackEvent } = useMixpanelService()
  const [createOpportunityApplication] = useCreateOpportunityApplicationMutation()

  if (!opportunity.job_url) {
    return null
  }

  return (
    <ExternalLinkButton
      isDisabled={opportunityApplication?.status === OPPORTUNITY_APPLICATION_STATUS.APPLIED}
      href={opportunity.job_url}
      onClick={async () => {
        if (!opportunityApplication) {
          await createOpportunityApplication({ talentUserId, opportunityId: opportunity.id, status: 'created' }).unwrap()
        }
        trackEvent(
          'Apply on Site Button Clicked',
          {
            opportunity_id: opportunity.id,
            opportunity_name: opportunity.name
          }
        )
      }}
      {...primaryButtonStyle}
    >
      {t('Apply')}
    </ExternalLinkButton>
  )
}

export default ExternalApplyButton