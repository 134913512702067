import React from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'i18n/TranslationContext'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import {
  Box,
  Flex,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react'
import { InputField, UploadMediaField, StyledFormLabel, AddSubtractButton, SupplementalMaterial } from 'components'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import { addForm } from 'utils/forms'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import OverlayBlock from 'components/OverlayBlock'
import OpportunitySelectorField from 'components/forms/fields/OpportunitySelectorField'

const CourseNameAndThumbnailForm = ({ formDisabled = false }) => {
  const { t } = useTranslation()
  const { register, unregister, watch, trigger, setFocus } = useFormContext() // retrieve all hook methods
  const errors = useSelector(state => state.courseBuilder.errors || {}) // Access errors from the Redux store
  const { helperTextVals, placeholderTextVals } = useGetFormTextValues()
  const totalCourseMaterialCount = 3
  const {
    courseMaterialCount,
    setCourseMaterialCount,
    courseNameList
  } = useCourseBuilderContext()

  const supplementalMaterialProp = {
    assetCount: courseMaterialCount,
    setAssetCount: setCourseMaterialCount,
    assetType: 'course',
    disabled: formDisabled,
    removeTextValue: t('Remove Supplemental Material')
  }

  const mediaType = React.useMemo(() => UPLOAD_MEDIA_TYPE(t), [t])
  return (
    <>
      <InputField
        label={t('Course Name')}
        name="name"
        width="60%"
        fieldRequired
        errorVal={errors}
        helperText={!formDisabled && helperTextVals.course_name}
        inputProps={{
          disabled: formDisabled,
          placeholder: placeholderTextVals.course_name,
          ...register(
            'name', {
              required: t('Course Name can’t be empty.'),
              maxLength: { value: 55, message: 'Max 55 characters allowed.' },
              validate: {
                isNameTaken: value => courseNameList.includes(value) ? t('Course Name is already taken.') : true
              }
            }
          )
        }}
      />
      <OverlayBlock isActive={!(watch('name'))} onClick={() => {
        trigger('name')
        setFocus('name')
      }}>
        <UploadMediaField
          name="image"
          label={t('Course Thumbnail')}
          helperText={!formDisabled && helperTextVals.course_image}
          inputProps={{ disabled: formDisabled }}
          mediaType={mediaType.THUMBNAIL}
          fieldRequired
        />
        <InputField
          label={t('Course Thumbnail Alt Text')}
          name="image_alt_text"
          inputProps={{
            disabled: formDisabled,
            maxLength: 250,
            placeholder: t('A woman in a wheelchair stares directly to camera with the Gotham city skyline behind her.'),
            ...register(
              'image_alt_text'
            )
          }}
        />
        <OpportunitySelectorField
          formDisabled={formDisabled}
          errorVal={errors}
        />
        {
          !formDisabled && (
            <Flex justifyContent="space-between">
              <Box>
                <FormControl pb="40px">
                  <StyledFormLabel>
                    {t('Add Supplemental Materials')}
                  </StyledFormLabel>
                  <FormHelperText fontFamily="Poppins" fontSize="20px" pb="20px">
                    {helperTextVals.course_additional_materials}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Flex>
          )
        }
        {courseMaterialCount.map((index, arrayIndex) => (
          <Box key={index}>
            <SupplementalMaterial index={index} errorVal={errors} supplementalMaterialProp={supplementalMaterialProp} unRegister={unregister} />
            {arrayIndex === courseMaterialCount.length - 1 && !formDisabled && (
              <AddSubtractButton
                determineType="add"
                textValue={t('Add Supplemental Material')}
                onClickAction={() => addForm(totalCourseMaterialCount, courseMaterialCount, setCourseMaterialCount)}
                currentCount={courseMaterialCount}
                totalCount={2}
                maxAmtTooltipText={t('Maximum assets added.')}
              />
            )}
          </Box>
        ))}
      </OverlayBlock>
    </>
  )
}

export default CourseNameAndThumbnailForm
