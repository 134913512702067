import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CoursesList } from './CoursesList'
import { Global } from '@emotion/react'
import { PartnersHeader } from './PartnersHeader'
import { SupportPage } from '../shared/SupportPage'
import { TalentListPage } from './TalentListPage'
import { CourseStatsPage } from './CourseStats'
import { CourseFeature } from '../CourseFeature'
import { CourseBuilder } from '../CourseBuilder'
import { AssignmentsListPage } from './AssignmentsListPage'
import { InviteTalentPage } from './InviteTalentPage'
import { OpportunityFeature } from 'OpportunityFeature'
import { EditProfile } from '../TalentProfile/Edit'
import { OpportunityBuilder } from '../OpportunityBuilder'

export const PartnerApp = () => {

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <>
            <Global
              styles={{
                body: {
                  backgroundColor: '#1A294F'
                }
              }}
            />
            <PartnersHeader />
            <Routes>
              <Route
                path="/courses"
                element={<CoursesList />}
              />
              <Route
                path="/courses/dashboard/:courseId"
                element={<CourseStatsPage />}
              />
              <Route
                path="/courses/dashboard/:courseId/invite"
                element={<InviteTalentPage />}
              />
              <Route
                path="/courses/dashboard/:courseId/assignments/*"
                element={<AssignmentsListPage />}
              />
              <Route
                path="/courses/new"
                element={<CourseBuilder />}
              />
              <Route
                path="/courses/:courseId/edit"
                element={<CourseBuilder />}
              />
              <Route
                path="/courses/:courseId/*"
                element={<CourseFeature />}
              />
              <>
                <Route
                  path="/opportunities/new"
                  element={<OpportunityBuilder />}
                />
                <Route
                  path="/opportunities/:opportunityId/edit"
                  element={<OpportunityBuilder />}
                />
              </>
              <Route
                path="/opportunities/*"
                element={<OpportunityFeature />}
              />
              <Route
                path="/talent/list/*"
                element={<TalentListPage />}
              />
              <Route
                path="/partner/edit"
                element={<EditProfile />}
              />
              <Route
                path="/support"
                element={<SupportPage />}
              />
              <Route path="/sign_up/*" element={<Navigate to="/courses" replace />} />
              <Route path="*" element={<Navigate to="/courses" replace />} />
            </Routes>
          </>
        }
      />
    </Routes>
  )
}
