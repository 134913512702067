import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Flex, Show, Tr, useDisclosure } from '@chakra-ui/react'
import { ProfileColumn } from '../TalentListPage/TalentItems/ProfileColumn'
import { Link, useLocation } from 'react-router-dom'
import { TableWrapper } from '../../shared/TableWrapper'
import { TableItem } from '../../shared/TableItem'
import { useTalentProfileListData } from './useTalentProfileListData'
import { useBuildBackTo } from '../useBuildBackTo'
import { TableItemMap } from '../../shared/TableItemMap'
import ProfileDrawer from 'components/partner/talent/listPage/profileDrawer/ProfileDrawer'
import { useDispatch } from 'react-redux'
import { setCurrentTalentProfileId } from 'features/partner_ux/partnerUxSlice'

const useAssignmentsLink = (id) => (
  `${useLocation().pathname}/talent_profile/${id}?backTo=${useBuildBackTo()}`
)

const AssignmentsLabel = ({ count }) => {
  const { t } = useTranslation()
  return t('completedAssignments', { count })
}

const Column1 = ({
  id,
  name,
  pronouns,
  profession,
  location,
  photo_url: photoUrl,
  photo_alt_text: photoAltText,
  submitted_assignment_count: count,
}) => {
  return (
    <ProfileColumn
      name={name}
      pronouns={pronouns}
      profession={profession}
      photoUrl={photoUrl}
      photoAltText={photoAltText}
      location={location}
      assignmentDrawerUrl={useAssignmentsLink(id)}
    >
      <Show below="767px">
        <Box
          fontFamily="Poppins"
          fontSize="14px"
          fontWeight="500"
          color="#070F22"
          opacity="0.6200000047683716"
        >
          <AssignmentsLabel count={count} />
        </Box>
      </Show>
    </ProfileColumn>
  )
}

const Column2 = ({ submitted_assignment_count: count }) => (
  <Show above="md">
    {count}
  </Show>
)

const Column3 = ({ id, onProfileClick }) => {
  const { t } = useTranslation()

  return (
    <Flex
      justifyContent="end"
      flexDirection={['column', null, 'row']}
    >
      <Button
        onClick={()=> onProfileClick(id)}
        colorScheme="zettaPurple"
        fontWeight="400"
      >
        {t('View profile')}
      </Button>
      <Box flexBasis="8px" />
      <Button
        as={Link}
        colorScheme="zettaBlue"
        fontWeight="400"
        to={useAssignmentsLink(id)}
      >
        {t('View assignments')}
      </Button>
    </Flex>
  )
}

export const TalentsTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure() // for search drawer

  // on click of view profile, set the talent profile id to the store,
  // and open the profile drawer
  const handleViewProfileClick = (id) => {
    dispatch(setCurrentTalentProfileId(id))
    onOpen()
  }

  return (
    <>
      <ProfileDrawer isOpen={isOpen} onClose={onClose} />
      <TableWrapper
        headings={[
          t('talent'),
          t('completed assignments'),
          t('actions')
        ]}
      >
        <TableItemMap
          listQuery={useTalentProfileListData()}
          emptyStateCopy={t('No talent found')}
        >
          {
            (props) => (
              <Tr key={props.id}>
                <TableItem
                  columns={[
                    <Column1 key={1} {...props} onProfileClick={handleViewProfileClick} />,
                    <Column2 key={2} {...props} />,
                    <Column3 key={3} {...props} onProfileClick={handleViewProfileClick} />
                  ]}
                />
              </Tr>
            )
          }
        </TableItemMap>
      </TableWrapper>
    </>
  )
}
