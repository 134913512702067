import { Box, Button, Flex, UnorderedList, ListItem, Text, VStack } from '@chakra-ui/react'
import { BlockWrapper } from 'components/talent/modalChatUx/BlockWrapper'
import { ResponsiveImage } from 'components/talent/modalChatUx/ResponsiveImage'
import { Body } from 'components'
import { BEACON_MODE } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'

const MaybeLaterButton = ({ onClose }) => {
  const { t } = useTranslation()
  return (
    <Button
      colorScheme="zettaBlue"
      onClick={onClose}
      fontWeight={400}
      variant="ghost"
      size="lg"
    >{t('Maybe Later')}</Button>
  )
}

const GetStartedButton = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      colorScheme="zettaBlue"
      onClick={onClick}
      fontWeight={700}
      size="lg"
    >
      {t('Get Started')}
    </Button>
  )
}

const SplashItem = ({ text }) => (
  <ListItem listStyleType="initial">
    <Text>{text}</Text>
  </ListItem>
)

const headerBoxProps = {
  as: 'h3',
  mb: '10px',
  fontSize: '46px',
  fontWeight: '700',
  fontFamily: 'Spline Sans Mono',
  textTransform: 'uppercase',
  textAlign: ['center', null, null, 'left'],
  className: 'compass-splash__title',
  marginInline: { base: 'auto', md: 0 }
}

export const SplashContent = ({ onModeChange, onClose }) => {
  const { t } = useTranslation()

  const splashItems = [
    t('Gather personalized guidance on your skills and career paths, while ensuring your data is confidential.'),
    t('Convert your lived experiences into transferable skills for your desired role and industry.'),
  ]

  return (
    <BlockWrapper p={4}>
      <Flex
        flexDirection={['column', null, 'row']}
        boxShadow="md"
        className="modal-splash-content"
      >
        <VStack p="40px" maxWidth={{ md: '65%' }} alignItems="flex-start" pt="50px" justifyContent="space-between">
          <Box {...headerBoxProps}>
            <Box
              as="span"
              display="block"
              fontSize="26px"
              mb="0"
            >
              {t('Introducing')}
            </Box>
            Making Space AI
          </Box>
          <Box className="splash-group">
            <UnorderedList spacing={3} fontSize="lg">
              { splashItems.map((text, i) => <SplashItem key={i} text={text} />) }
            </UnorderedList>
          </Box>
          <Body fontSize="17px" pb="8px">
            <i>
              {t('This feature is currently in Beta. We thank you for your feedback while we’re still fine-tuning {{MAKING_SPACE_AI}}.',
                { MAKING_SPACE_AI: 'Making Space AI' })}
            </i>
          </Body>
          <Box className="splash-buttons">
            <GetStartedButton onClick={() => onModeChange(BEACON_MODE.CHAT)} />
            <MaybeLaterButton onClose={onClose} />
          </Box>
        </VStack>

        {/* Splash Image */}
        <Box display={['none', null, 'block']}>
          <ResponsiveImage
            imageSrc={`${process.env.PUBLIC_URL}/msai-splash.png`}
            altText={t('graphic in the style of a compass')}
          />
        </Box>
      </Flex>
    </BlockWrapper>
  )
}
