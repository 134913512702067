import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'
import SkillsGrid from './SkillsGrid'
import { PurpleTapeDisplay, SubTitleLabel } from 'components/display'
import { useTranslation } from 'i18n/TranslationContext'

const CourseSection = ({ course }) => {
  const { t } = useTranslation()
  if (!course) return null

  return (
    <Box pt={8}>
      <PurpleTapeDisplay as="h2" text={t('Related Courses')} fontSize="20px" width="100%" mt={{ base: 4, small: 0 }} />
      <Box mt={6} ml={4} mb={8}>
        <Flex gap={4}>
          <Image
            src={course?.poster || ''}
            alt={course?.name || t('Course Poster')}
            width="100px"
            height="70px"
            borderRadius="md"
          />
          <Box>
            <Text fontWeight="bold">
              {course?.name || t('Course Name Not Available')}
            </Text>
            <SubTitleLabel
              authors={course?.authors || []}
              visibility={course?.visibility || ''}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

const OpportunityDetails = ({ opportunity }) => {
  const { t } = useTranslation()

  return (
    <Box>
      {opportunity?.skills.length > 0 && (
        <Box mb={16}>
          <PurpleTapeDisplay as="h2" text={t('Skills')} fontSize="20px" width="100%" mt={{ base: 4, small: 0 }} />
          <SkillsGrid skills={opportunity?.skills} />
        </Box>
      )}

      <CourseSection course={opportunity.course} />
    </Box>
  )
}

export default OpportunityDetails
