import { Box, Flex } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useLocation } from 'react-router-dom'
import { useListAssignmentsQuery } from 'features/submittedAssignmentApi'
import { useShowQuizAssessmentQuery } from 'features/quiz/quizzesApi'
import { AppLoadingState } from 'AppLoadingState'
import QuizAssessment from 'components/partner/talent/listPage/profileDrawer/QuizAssessment'
import AssignmentDownloadButtons from 'components/partner/talent/listPage/profileDrawer/AssignmentDownloadButtons'

export const CourseAssessments = ({
  data: {
    id: talentProfileId,
  },
  onClose
}) => {
  const { t } = useTranslation()

  const url = useLocation()
  const currentParams = new URLSearchParams(url.search)
  const courseId = currentParams.get('course_id')

  const { data: assignments = [], isLoading: isLoadingAssignments } = useListAssignmentsQuery({
    episodePath: `/courses/${courseId}`,
    talentProfileId
  })

  const { data: quizAssessments = [], isLoading: isLoadingQuizAssessments } = useShowQuizAssessmentQuery({
    episodePath: `/courses/${courseId}`,
    talentProfileId
  })

  if (isLoadingAssignments || isLoadingQuizAssessments || !assignments || !quizAssessments) {
    return <AppLoadingState />
  }

  return (
    <Flex
      flexDirection={['column', null, 'row']}
      p={4}
    >
      <Box
        id="course-assessment-label"
        color="var(--Navy-Blue, #0F204A)"
        fontFamily="Spline Sans Mono"
        fontSize="32px"
        fontWeight="bold"
        textTransform="uppercase"
        mb={4}
        mr={8}
      >
        {t('Assessments')}
      </Box>
      <Flex flexWrap="wrap" role="list" aria-labelledby="course-assessment-label">
        {quizAssessments.length > 0 && <QuizAssessment quizAssessments={quizAssessments} />}
        <AssignmentDownloadButtons assignments={assignments} talentProfileId={talentProfileId} />
      </Flex>
    </Flex>
  )
}
