import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Button,
  Center,
  Show
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { HiTv, HiOutlineRectangleGroup } from 'react-icons/hi2'
import { FaLightbulb } from 'react-icons/fa'
import { LogOutMenuItem } from 'features/sessions'
import { HeaderLayout, MenuItemBase, MenuItemBasicLinkButton } from './HeaderParts'
import { forwardRef } from 'react'
import { HeaderDropdown, OpportunitiesButton } from 'components'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { AppLoadingState } from 'AppLoadingState'
import { URL } from 'constants'

const MobileShareFeedbackButton = forwardRef(function MobileShareFeedbackButton(props, ref) {
  const { t } = useTranslation()
  return (
    <Button
      ref={ref}
      {...props}
      colorScheme="zettaBlue"
      fontWeight="500"
      variant="solid"
      href={URL.FEEDBACK_FORM}
    >
      {t('Share Feedback')}
    </Button>
  )
})

const MenuItem = ({ children, destination }) => (
  <MenuItemBase
    as={NavLink} to={destination} end
    color="rgba(15, 32, 75, 1)"
    opacity="0.62"
    _activeLink={{
      color: 'rgba(255, 125, 25, 1)',
      opacity: '1'
    }}
  >
    {children}
  </MenuItemBase>
)


const MobileHeader = () => {
  const { t } = useTranslation()

  return (
    <>
      <MenuItem destination="/courses">
        <HiTv /> <Box paddingLeft="8px">{t('Courses')}</Box>
      </MenuItem>
      <MenuItem destination="/opportunities">
        <HiOutlineRectangleGroup /> <Box paddingLeft="8px">{t('Opportunities')}</Box>
      </MenuItem>
      <MenuItem destination="/support">
        <FaLightbulb /> <Box paddingLeft="8px">{t('Support')}</Box>
      </MenuItem>
      <MobileShareFeedbackButton
        as={MenuItemBasicLinkButton}
      />
      <LogOutMenuItem />
    </>
  )
}

const DesktopHeader = () => {
  const { t } = useTranslation()
  const { data, isFetching } = useGetCurrentUserQuery()

  if (isFetching) {
    return <AppLoadingState />
  }

  return (
    <>
      <Center flexGrow="1">
        <Button
          as={NavLink}
          end
          to="/courses"
          variant="link"
          leftIcon={<Box as={HiTv} fontSize="24px" />}
          fontWeight="500"
          style={{
            textDecoration: 'none'
          }}
          color="white"
          opacity="0.62"
          _activeLink={{
            color: 'rgba(255, 125, 25, 1)',
            opacity: '1'
          }}
        >
          {t('Courses')}
        </Button>
        <Box flexBasis="50px" />
        <Box>
          <OpportunitiesButton
            color="white"
            opacity="0.62"
            _activeLink={{
              color: 'rgba(255, 125, 25, 1)',
              opacity: '1'
            }}
          />
        </Box>
      </Center>
      <HeaderDropdown headerType="partner" photoUrl={data?.partner_profile?.photo_url} name={data?.talent_profile?.name} />
    </>
  )
}

export const PartnersHeader = ({ backTo }) => (
  <HeaderLayout backTo={backTo}>
    <Show breakpoint="(max-width: 767px)">
      <MobileHeader />
    </Show>
    <Show above="md">
      <DesktopHeader />
    </Show>
  </HeaderLayout>
)
