import { Button } from '@chakra-ui/react'
import { useState } from 'react'
import { useGetStepInfo } from 'CourseFeature/hooks'
import { DIRECTION } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'
import { useGetOpportunityQuery } from 'features/opportunitiesApi'
import ExternalLinkButton from 'components/buttons/ExternalLinkButton'
import { useMixpanelService } from 'services/mixpanelService'
import { NavLink } from 'react-router-dom'

const buttonBase = {
  fontSize: '18px',
  fontWeight: '600',
  fontFamily: 'Spline Sans Mono',
  colorScheme: 'zettaBlue',
  size: 'lg',
}

const ExternalOpportunityButton = ({ opportunity }) => {
  const { trackEvent } = useMixpanelService()
  const { t } = useTranslation()
  const [isClicked, setIsClicked] = useState(false)

  return (
    isClicked ?
      <Button {...buttonBase} as={NavLink} to={`/opportunities/${opportunity.id}`}>
        {t('View Opportunity')}
      </Button>
      :
      <ExternalLinkButton
        href={opportunity.job_url}
        onClick={() => {
          trackEvent(
            'Apply on Site Button Clicked',
            {
              opportunity_id: opportunity.id,
              opportunity_name: opportunity.name
            }
          )
          setIsClicked(true)
        }}
        {...buttonBase}
      >
        {t('Apply')}
      </ExternalLinkButton>
  )
}

export const NextStepButton = ({
  stepId,
  courseId,
  onNextStepClick,
  talentUserId,
  opportunityId,
  isCourseComplete,
  onApply
}) => {
  const { data: opportunity, isLoading } = useGetOpportunityQuery(
    { id: opportunityId, talentUserId: talentUserId },
    { skip: !opportunityId || !talentUserId }
  )
  const nextStepInfo = useGetStepInfo(stepId, DIRECTION.NEXT)
  const { t } = useTranslation()

  const handleGoToNextStep = () => {
    onNextStepClick(`/courses/${courseId}/steps/${nextStepInfo.id}`)
  }

  const handleGoToCourses = () => {
    onNextStepClick('/courses')
  }

  if (nextStepInfo) {
    if (nextStepInfo.isAssignment && !nextStepInfo.isDisabled) {
      return <Button {...buttonBase} onClick={handleGoToNextStep}>{t('Go to Assignment')}</Button>
    } else if (!nextStepInfo.isAssignment) {
      return <Button {...buttonBase} onClick={handleGoToNextStep}>{t('Go to Next Episode')}</Button>
    }
  }

  if (isLoading) {
    return <Button {...buttonBase} isLoading />
  }

  if (isCourseComplete && opportunity && talentUserId) {
    if (opportunity.job_url) {
      return <ExternalOpportunityButton opportunity={opportunity} />
    } else {
      return <Button {...buttonBase} onClick={onApply}>{t('Apply Now')}</Button>
    }
  }

  return <Button {...buttonBase} onClick={handleGoToCourses}>{t('Return to My Courses')}</Button>
}
