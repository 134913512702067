import React from 'react'
import { VisuallyHidden, Box } from '@chakra-ui/react'
import parse from 'html-react-parser'
import { useGetTextObjectQuery } from 'features/awsApiSlice'
import { useTranslation } from 'i18n/TranslationContext'

const Slide = ({ slide, index, isCurrentSlide, slideRefs, totalLength }) => {
  const { data: speakerNotes } = useGetTextObjectQuery({ objectURL: slide?.speakerNotes })
  const { t } = useTranslation()
  return (
    <Box
      key={index}
      ref={(el) => (slideRefs.current[index] = el)}
      tabIndex={-1}
      aria-hidden={!isCurrentSlide}
      display={isCurrentSlide ? 'block' : 'none' }
      position="relative"
    >
      <img src={slide.image} alt="" aria-hidden="true" />
      <VisuallyHidden
        as="section"
        title={t('Slide {{slideNum}} of {{totalSlides}}', { slideNum: index + 1, totalSlides: totalLength })}
        position="absolute"
        top="0"
        whiteSpace="initial"
        width="100%"
        height="100%"
      >
        {slide?.screenreaderText && parse(slide.screenreaderText)}
        {speakerNotes && (
          <>
            <h4>{t('Slide Speaker Notes:')}</h4>
            <p>{speakerNotes}</p>
          </>
        )}
      </VisuallyHidden>
    </Box>
  )
}

export default Slide