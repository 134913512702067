import { Box } from '@chakra-ui/react'

const InnerContainer = ({ children, ...restProps }) => {
  return (
    <Box
      className="inner-container"
      width="100%"
      maxWidth={{ base: '1200px', xl: '1400px' }}
      mx="auto"
      px={{ base: 6, md: 8 }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

export default InnerContainer