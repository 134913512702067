import React from 'react'
import { Box, Grid } from '@chakra-ui/react'
import { OpportunityDetails, PurpleTapeDisplay } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { parseWithoutStyles } from 'utils/string'
import parse from 'html-react-parser'
import InnerContainer from 'shared/InnerContainer'

const DescriptionSection = ({ opportunity, formattedEmploymentType }) => {
  const { t } = useTranslation()
  const description = opportunity?.description || ''

  return (
    <InnerContainer>
      <Grid
        templateColumns={{ base: '1fr', md: '2fr 1fr' }}
        gap={{ base: 6, md: 12 }}
        mb={8}
      >
        <Box>
          <PurpleTapeDisplay
            width={{ base: '100%', md: '80%' }}
            size="lg"
            as="h2"
            text={t('Opportunity Description')}
            fontSize={{ base: '26px', md: '32px' }}
          />
          <Box mx={[2, 6]}>
            <Box
              mt={4}
              whiteSpace="pre-wrap"
              fontFamily="Poppins"
              className="about-block__description"
              listStylePosition="outside"
            >
              {parse(description, parseWithoutStyles)}
            </Box>
          </Box>
        </Box>

        <OpportunityDetails opportunity={opportunity} formattedEmploymentType={formattedEmploymentType} />
      </Grid>
    </InnerContainer>
  )
}

export default DescriptionSection
