import { useEffect, useState } from 'react'
import { Box, HStack, Text } from '@chakra-ui/react'
import { IoMdStar } from 'react-icons/io'

import { coreIconStyles } from 'components/talent/quiz/modalElements/QuizContainer'

// TODO: Replace with chakra variables
const DARK_BLUE = '#0F204B'
const SELECTED_LIGHT_BLUE = '#3E00B2'
const SELECTED_BORDER_ORANGE = '#F09E00'
const HOVER_LIGHT_BLUE = '#394B77'
const ANSWER_BORDER_COLOR = '#e5e5e5'

const AnswerIcon = ({ iconLetter, isSelected }) => {
  return isSelected ? (
    <Box color={DARK_BLUE} {...coreIconStyles} bgColor="transparent">
      <IoMdStar
        size={50}
        color={SELECTED_BORDER_ORANGE}
        style={{ position: 'absolute', marginTop: '-4px' }}
      />
      <span style={{ color: DARK_BLUE, fontSize: '18px', zIndex: 1 }}>
        {iconLetter}
      </span>
    </Box>
  ) : (
    <Box borderRadius="50%" color={DARK_BLUE} {...coreIconStyles}>
      {iconLetter}
    </Box>
  )
}
export const AnswerOption = ({ answer, isSelected, iconLetter, onClick, isDisabled, isActiveInstance }) => {
  const [isHovered, setIsHovered] = useState(false)
  const bgColor = isSelected ? SELECTED_LIGHT_BLUE : isHovered ? HOVER_LIGHT_BLUE : DARK_BLUE

  useEffect(() => { if ( isDisabled ) { setIsHovered(false) }}, [isDisabled])

  const handleKeyDown = (e) => {
    // if space bar, simulate click
    if (e.key === ' ' && !isDisabled) {
      onClick()
    }
  }

  const keyboardProps = isActiveInstance ? { tabIndex: 0, onKeyDown: handleKeyDown } : {}

  return (
    <Box
      className="quiz-answer"
      bgColor={bgColor}
      border="1px"
      borderColor={isSelected ? SELECTED_BORDER_ORANGE : ANSWER_BORDER_COLOR}
      borderRadius="20px"
      color="white"
      cursor={isDisabled ? 'default' : 'pointer'}
      p={3}
      onClick={onClick}
      onMouseEnter={() => !isDisabled && setIsHovered(true)}
      onMouseLeave={() => !isDisabled && setIsHovered(false)}
      { ...keyboardProps }
    >
      <HStack spacing={3}>
        <AnswerIcon iconLetter={iconLetter} isSelected={isSelected} />
        <Text>{answer}</Text>
      </HStack>
    </Box>
  )
}
