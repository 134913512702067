import { Box } from '@chakra-ui/react'
import { AppLoadingState } from 'AppLoadingState'
import { EnrolledCourses } from 'Partner/TalentProfileView/EnrolledCourses'
import { CourseAssessments } from 'Partner/TalentProfileView/CourseAssessments'
import { useLoadTalentProfileFromStore } from 'Partner/useLoadTalentProfileFromStore'
import { ProfileCard } from 'components/partner/talent/listPage/profileDrawer/ProfileCard'
import { useListCourseForPartnerQuery } from 'features/coursesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

export const ProfileContent = ({ onClose }) => {
  const { data, isFetching } = useLoadTalentProfileFromStore()
  const {
    data: { partner_profile: { id:partnerId } }
  } = useGetCurrentUserQuery()
  const { data: partnerCoursesData } = useListCourseForPartnerQuery(partnerId)

  // Intersect enrolled course Ids with courses from this partner
  const enrolledIds = data?.enrolled_courses?.map(course => course.id) || []
  const partnerCourses = partnerCoursesData?.filter(
    ({ id }) => enrolledIds.includes(id)
  ) || []

  if (isFetching || !data || !partnerCoursesData) {
    return <AppLoadingState customBgColor="transparent" />
  }

  return (
    <>
      <Box
        paddingTop={['26px', null, '38px']}
        paddingLeft={['48px', null, '48px']}
        paddingRight={['48px', null, 0]}
        paddingBottom="82px"
        borderBottom="1px rgba(15, 32, 74, 0.1) solid"
        className="talent-profile-page-content"
      >
        <ProfileCard
          data={data}
          onClose={onClose}
        />
      </Box>
      <Box
        paddingTop={['26px', null, '38px']}
        paddingLeft={['28px', null, '28px']}
        paddingRight={['28px', null, 0]}
        paddingBottom="82px"
        background="#fff"
      >
        <CourseAssessments
          data={data}
        />
      </Box>
      <Box
        paddingTop={['26px', null, '38px']}
        paddingLeft={['28px', null, '28px']}
        paddingRight={['28px', null, 0]}
        paddingBottom="82px"
        background="#CDF3FF"
      >
        <EnrolledCourses
          courses={partnerCourses}
        />
      </Box>
    </>
  )
}

export default ProfileContent
