export const buttonStyles = () => {
  const primaryButtonStyle = {
    bgColor: '#1A365D', // Dark blue background
    color: 'white',
    fontWeight: 'normal',
    _hover: {
      bgColor: '#1A365D',
    },
    width: ['100%', '100%', 'fit-content'],
    minWidth: [null, null, '220px'],
  }

  return {
    primaryButtonStyle,
  }
}
