import { Box, Center, Spinner, useUpdateEffect } from '@chakra-ui/react'
import { SystemBubble, UserBubble } from 'components/talent/quiz/modalElements/bubbles'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { WarningIcon } from '@chakra-ui/icons'
import { useTranslation } from 'i18n/TranslationContext'

const NoMessagesElement = ({ t }) => (
  <Center p={10} flexDirection="column">
    <Spinner size="xl" color="blue.500" my={20} />
    <Box fontSize="xl" color="grey">{`{ ${t('{{MAKING_SPACE_AI}} is thinking', { MAKING_SPACE_AI: 'Making Space AI' })} }`}</Box>
  </Center>
)

const HasErrorElement = ({ t }) => (
  <Center p={10} flexDirection="column">
    <WarningIcon boxSize={10} color="red.500" my={20} />
    <Box fontSize="xl" color="red">{`{ ${t('{{MAKING_SPACE_AI}} is having trouble thinking', { MAKING_SPACE_AI: 'Making Space AI' })} }`}</Box>
  </Center>
)

export const ChatContainer = () => {
  const { t } = useTranslation()
  const { data } = useGetCurrentUserQuery()
  const { messages, error } = useSelector((state) => state.beaconChat)
  const [bubbles, setBubbles] = useState([])
  const bottomRef = useRef(null)

  useEffect(() => {
    if (messages) {
      const newBubbles = messages.map((message, index) => {
        const isUserMessage = message.role === 'user'
        return isUserMessage ? (
          <UserBubble key={index} message={{ markdown: message.text }} />
        ) : (
          <SystemBubble key={index} message={{ markdown: message.text }} isBeacon />
        )
      })
      setBubbles(newBubbles)
    }
    return () => { setBubbles([])}
  }, [messages, data])

  useUpdateEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [bubbles])

  return (
    <Box className="beacon-modal-chat-container">
      { bubbles.length ? [...bubbles].reverse().map((bubble, index) => (
        <Box key={index} my={8}>{bubble}</Box>
      )) : error ? <HasErrorElement t={t} /> : <NoMessagesElement t={t} /> }
      {/* This is a dummy element that we use to scroll to the bottom of the chat */}
      <Box ref={bottomRef} />
    </Box>
  )
}
