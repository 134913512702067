import React from 'react'
import { Box } from '@chakra-ui/react'
import PurpleTape from './PurpleTape'

const PurpleTapeDisplay = ({ size = 'md', text, ...props }) => (
  <Box
    pt={3}
    pb={3}
    display="inline-flex"
    maxWidth="100%"
    fontFamily="Spline Sans Mono"
    fontWeight="bold"
    textTransform="uppercase"
    alignItems="center"
    position="relative"
    color="white"
    minHeight={{ base: size === 'lg' ? '50px' : '30px', md: size === 'lg' ? '75px' : '45px' }}
    zIndex={21}
    {...props}
  >
    <PurpleTape />
    <Box pl="24px" pr="24px" mb={0}>
      {text}
    </Box>
  </Box>
)

export default PurpleTapeDisplay
