import React from 'react'
import { Box, Flex, Image, Text, Icon } from '@chakra-ui/react'
import { OrangeTapeDisplay } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { SaveOpportunityButton, ApplyButton } from 'components'
import IveAppliedButton from './IveAppliedButton'
import { useGetOpportunityAppForTalentQuery } from 'features/opportunitiesApi'
import { OPPORTUNITY_SALARY_FREQUENCY_LABELS, OPPORTUNITY_WORKPLACE_MODEL_DISPLAY } from 'constants'
import { HiOutlineCurrencyDollar, HiBriefcase, HiOutlineBuildingOffice2 } from 'react-icons/hi2'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import InnerContainer from 'shared/InnerContainer'

const CompanyHeader = ({ poster, companyName }) => {
  const { t } = useTranslation()
  return (
    <Flex alignItems="center" gap={4} mb={[6, 12]}>
      {poster ? (
        <Image
          src={poster}
          alt={companyName || t('Company Logo')}
          boxSize="55px"
          borderRadius="full"
          bg="white"
          objectFit="contain"
        />
      ) : (
        <Box
          boxSize="55px"
          borderRadius="full"
          bg="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={HiOutlineBuildingOffice2} boxSize="36px" color="gray.700" />
        </Box>
      )}
      <Text fontSize="20px" fontWeight="400" color="gray.700" fontFamily="Poppins">{companyName}</Text>
    </Flex>
  )
}

const DetailRow = ({ icon, label }) => {
  return (
    <Flex alignItems="center">
      <Icon boxSize="1.25em" marginInlineEnd={2} as={icon} />
      <Text color="gray.700" fontFamily="Poppins">{label}</Text>
    </Flex>
  )
}

const WorkplaceModel = ({ workplaceModel }) => {
  const { t } = useTranslation()
  if (!workplaceModel) return null

  const workplaceModelLabels = OPPORTUNITY_WORKPLACE_MODEL_DISPLAY(t)
  return (
    <DetailRow
      icon={workplaceModelLabels[workplaceModel].icon}
      label={workplaceModelLabels[workplaceModel].label}
    />
  )
}

const EmploymentType = ({ employmentType }) => {
  if (!employmentType) return null

  return (
    <DetailRow
      icon={HiBriefcase}
      label={employmentType}
    />
  )
}

const SalaryRange = ({ salaryMin, salaryMax, salaryFrequency }) => {
  const { t } = useTranslation()

  const formatSalary = (salary) => {
    const formattedSalary = Number(salary).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return formattedSalary
  }

  if (!salaryMin || !salaryMax || !salaryFrequency) return null

  let salaryRange = `$${formatSalary(salaryMin)} - $${formatSalary(salaryMax)}`

  if (salaryMin === salaryMax) {
    salaryRange = `$${formatSalary(salaryMin)}`
  }

  const salaryFrequencyLabels = OPPORTUNITY_SALARY_FREQUENCY_LABELS(t)

  return (
    <DetailRow
      icon={HiOutlineCurrencyDollar}
      label={`${salaryRange} / ${salaryFrequencyLabels[salaryFrequency]}`}
    />
  )
}

const OpportunityLocation = ({ location }) => {
  const { t } = useTranslation()
  if (!location) return null

  return (
    <DetailRow
      icon={HiOutlineLocationMarker}
      label={location.formatted_address || t('Location not available')}
    />
  )
}

const HeaderSection = ({ opportunity, talentUser, formattedEmploymentType }) => {
  const { t } = useTranslation()

  const { data: oppApplication, isLoading: isLoadingOppApplication } = useGetOpportunityAppForTalentQuery({
    oppId: opportunity.id,
    talentUserId: talentUser.id
  })

  const getButtonsComponent = () => {
    if (talentUser.experience_type === 'partner_experience') {
      return null
    }

    if (oppApplication) {
      return (
        <Flex gap={4} mt="20px" flexWrap="wrap" mb={[4, 9]}>
          <ApplyButton
            opportunity={opportunity}
            colorScheme="zettaBlue"
            fontSize="16px"
            fontWeight="500"
            padding="0 12px"
          />
          {opportunity.job_url && (
            <IveAppliedButton
              opportunityApplication={oppApplication}
              opportunity={opportunity}
            />
          )}
        </Flex>
      )
    } else {
      return (
        <Flex gap={4} mt="20px" mb={[4, 9]}>
          <SaveOpportunityButton
            opportunityId={opportunity.id}
            talentUserId={talentUser.id}
            colorScheme="zettaBlue"
            fontSize="16px"
            fontWeight="500"
            padding="0 12px"
          />
          <ApplyButton
            opportunity={opportunity}
            talentUserId={talentUser.id}
            colorScheme="zettaBlue"
            fontSize="16px"
            fontWeight="500"
            padding="0 12px"
          />
        </Flex>
      )
    }
  }

  return (
    <Box
      bg="#E5E0DD"
      py={10}
      px={4}
      mt={-8}
      position="relative"
    >
      <InnerContainer>
        <CompanyHeader poster={opportunity.partner_profile?.photo_url} companyName={opportunity.partner_profile?.company_name} />
        <Flex alignItems="flex-start" gap={4}>
          <Box>
            <Box mt={4}>
              <OrangeTapeDisplay
                as="h1"
                title={opportunity.name || t('Unknown Opportunity')}
                pb="10px"
                pt="10px"
              />
            </Box>

            <Flex alignItems="center" gap={4} marginTop={6} mb={[4, 9]} flexWrap="wrap">
              <WorkplaceModel workplaceModel={opportunity.workplace_model} />
              <EmploymentType employmentType={formattedEmploymentType} />
              <SalaryRange salaryMin={opportunity.salary_min} salaryMax={opportunity.salary_max} salaryFrequency={opportunity.salary_frequency} />
              <OpportunityLocation location={opportunity.location} />
            </Flex>

            {!isLoadingOppApplication &&
              getButtonsComponent()
            }
          </Box>
        </Flex>
      </InnerContainer>
    </Box>
  )
}

export default HeaderSection
